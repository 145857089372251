<template>
  <div>
    <ManageKnowledge />
  </div>
</template>
<script>
import ManageKnowledge from "@/components/Knowledge/ManageKnowledge";
export default {
  components: {
    ManageKnowledge,
  },
  created() {},
};
</script>
